import React from "react";
import { FadeLoader } from "react-spinners";

function Splash() {
  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <img
        src="/img/suonovivo.png"
        alt=""
        className="w-3/4 mb-20 lg:w-1/2 xl:w-1/3"
      />
      <FadeLoader color="#FFFFFF" className="w-10" />
    </div>
  );
}

export default Splash;
