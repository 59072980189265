import React, { useState, useEffect } from "react";
import { CgLoadbarSound } from "react-icons/cg";
import axios from "axios";

function Current() {
  const [name, setName] = useState("Current");
  const [artist, setArtist] = useState("Samuele Riva");
  const [img, setImg] = useState("/img/asd.jfif");

  useEffect(() => {
    fetchcurrent();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchcurrent = () => {
    axios
      .get("https://spotibox-api.suonovivo.net/current")
      .then((response) => {
        setName(response.data.name);
        setArtist(response.data.artists.join(", "));
        setImg(response.data.image);
      })
      .catch((e) => {
        console.log(e);
      });
    setTimeout(fetchcurrent, 5000);
  };

  return (
    <div className="box-border p-5 pt-0 h-28">
      <div className="bg-neutral-600 rounded-2xl w-full h-full p-4 flex items-center relative">
        <img src={img} alt="" className="max-h-full h-16 mr-5 rounded-lg" />
        <div>
          <p className="font-medium text-lg text-white">{name}</p>
          <p className="text-sm text-gray-100">{artist}</p>
        </div>
        <CgLoadbarSound size={35} className="absolute right-8 text-white" />
      </div>
    </div>
  );
}

export default Current;
