import React from "react";
import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";

function Feedback() {
  return (
    <div className="vh flex flex-col justify-center items-center h-screen">
      <img src="/img/feed.png" alt="" className="lg:w-1/2 xl:w-1/3" />
      <p className="font-bold text-2xl text-center mt-5">
        Thanks for your vote!
      </p>
      <p className="mt-3 mb-32">We counted you in, enjoy!</p>
      <Link to="/">
        <button className="rounded-full bg-blue-500 p-4 px-5 flex justify-center items-center">
          <IoArrowBack className="text-white mr-2" />
          <p className="font-semibold text-white">Back</p>
        </button>
      </Link>
    </div>
  );
}

export default Feedback;
