import React from "react";
import { MdClose } from "react-icons/md";
import { HiEmojiSad } from "react-icons/hi";

function Popup({ popupOpen, setPopupOpen }) {
  const onClose = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <div
        className="absolute top-0 left-0 bg-black opacity-30 w-full h-full z-20 cursor-pointer"
        onClick={onClose}
      ></div>
      <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-30 bg-neutral-700 rounded-2xl w-10/12 md:w-2/3 xl:w-4/12">
        <div className="p-5 relative flex flex-col justify-center items-center h-full box-border">
          <div className="absolute top-5 right-5">
            <MdClose className="text-white" size={25} onClick={onClose} />
          </div>
          <HiEmojiSad className="text-red-500 mt-10" size={50} />
          <div>
            <p className="text-red-300 text-lg font-semibold text-center mt-10">
              Ops! Something went wrong
            </p>
            <p className="text-white text-center mt-2 mb-10">
              You have already voted for this poll, wait for the next one!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Popup;
