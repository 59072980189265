import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Body({ popupOpen, setPopupOpen }) {
  const [bg, setBg] = useState([
    "/img/default.webp",
    "/img/default.webp",
    "/img/default.webp",
    "/img/default.webp",
  ]);
  const [names, setNames] = useState(["", "", "", ""]);
  const [artists, setArtists] = useState(["", "", "", ""]);
  const [ids, setIds] = useState(["", "", "", ""]);
  const [voteId, setVoteId] = useState("");

  let navigate = useNavigate();

  const fetchId = () => {
    console.log("fetching id");
    axios
      .get("https://spotibox-api.suonovivo.net/currentPoll")
      .then((response) => {
        if (response.data.pollId !== voteId) {
          setNames(response.data.songs.map((x) => x.name));
          setArtists(response.data.songs.map((x) => x.artists.join(", ")));
          setBg(response.data.songs.map((x) => x.image));
          setVoteId(response.data.pollId);
          setIds(response.data.songs.map((x) => x.id));
        }
      });

    setTimeout(fetchId, 5000);
  };

  useEffect(() => {
    fetchId();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (songId) => {
    if (
      !localStorage.getItem("voteId") ||
      localStorage.getItem("voteId") < voteId
    ) {
      const payload = {
        pollId: voteId,
        songId,
      };
      axios
        .post("https://spotibox-api.suonovivo.net/votes", payload)
        .then(() => {
          navigate("/thanks");
        })
        .catch((e) => {
          console.log(e);
        });
      localStorage.setItem("voteId", voteId);
    } else {
      setPopupOpen(true);
    }
  };

  return (
    <div className="grid grid-cols-2 grid-rows-2 gap-5 p-5 h-full row-span-4">
      <div
        className="rounded-2xl bg-cover flex flex-col items-center justify-center bg-no-repeat bg-center relative p-5 cursor-pointer"
        style={{ backgroundImage: `url(${bg[0]})` }}
        onClick={() => {
          handleSubmit(ids[0]);
        }}
      >
        <div className="w-full h-full rounded-2xl bg-black opacity-50 absolute"></div>
        <p className="text-white text-center font-medium text-xl z-10 md:text-2xl">
          {names[0]}
        </p>
        <p className="text-white z-10 md:text-lg text-center mt-3">
          {artists[0]}
        </p>
      </div>
      <div
        className="rounded-2xl bg-cover flex flex-col items-center justify-center bg-no-repeat bg-center relative p-5 cursor-pointer"
        style={{ backgroundImage: `url(${bg[1]})` }}
        onClick={() => {
          handleSubmit(ids[1]);
        }}
      >
        <div className="w-full h-full rounded-2xl bg-black opacity-50 absolute"></div>
        <p className="text-white text-center font-medium text-xl z-10 md:text-2xl">
          {names[1]}
        </p>
        <p className="text-white z-10 md:text-lg text-center mt-3">
          {artists[1]}
        </p>
      </div>
      <div
        className="rounded-2xl bg-cover flex flex-col items-center justify-center bg-no-repeat bg-center relative p-5 cursor-pointer"
        style={{ backgroundImage: `url(${bg[2]})` }}
        onClick={() => {
          handleSubmit(ids[2]);
        }}
      >
        <div className="w-full h-full rounded-2xl bg-black opacity-50 absolute"></div>
        <p className="text-white text-center font-medium text-xl z-10 md:text-2xl">
          {names[2]}
        </p>
        <p className="text-white z-10 md:text-lg text-center mt-3">
          {artists[2]}
        </p>
      </div>
      <div
        className="rounded-2xl bg-cover flex flex-col items-center justify-center bg-no-repeat bg-center relative p-5 cursor-pointer"
        style={{ backgroundImage: `url(${bg[3]})` }}
        onClick={() => {
          handleSubmit(ids[3]);
        }}
      >
        <div className="w-full h-full rounded-2xl bg-black opacity-50 absolute"></div>
        <p className="text-white text-center font-medium text-xl z-10 md:text-2xl">
          {names[3]}
        </p>
        <p className="text-white z-10 md:text-lg text-center mt-3">
          {artists[3]}
        </p>
      </div>
    </div>
  );
}

export default Body;
