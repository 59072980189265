import React, { useEffect, useState } from "react";
import Nav from "./Nav";
import Body from "./Body";
import Current from "./Current";
import Splash from "./Splash";
import Popup from "./Popup";

function Main() {
  const [splashActive, setSplashActive] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    setSplashActive(true);
    setTimeout(() => setSplashActive(false), 3000);
  }, []);

  return (
    <div className="vh box-border h-screen flex flex-col bg-neutral-700">
      {splashActive ? (
        <Splash />
      ) : (
        <>
          {popupOpen ? (
            <Popup popupOpen={popupOpen} setPopupOpen={setPopupOpen} />
          ) : (
            <></>
          )}
          <Nav />
          <Body popupOpen={popupOpen} setPopupOpen={setPopupOpen} />
          <Current />
        </>
      )}
    </div>
  );
}

export default Main;
